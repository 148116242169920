<template>
  <vs-card>
    <div slot="header" class="py-8">
      <h2>
        {{ title }}
      </h2>
      <h4 class="pt-1">
        {{ subtitle }}
      </h4>
      <small v-if="descriptionTitle" class="px-8">{{descriptionTitle}}</small>
    </div>
    <div class="px-8 pb-8">
      <slot></slot>
    </div>
  </vs-card>
</template>
<script>
export default {
  name: "custom-card",
  props: ["title", "subtitle", "descriptionTitle"]
};
</script>
<style lang="scss" scoped>
  $border-bottom: rgba(var(--vs-warning), 1.0);
  h2{
    font-weight: 600;
  }

  h4{
    font-weight: 600;
    color: #6B6B6B;
  }

  small {
    font-size: 14px;
    text-align: center;
    display: block;
    margin: 1em  auto 0;
  }

  .con-vs-card {
    border-bottom: solid 7px $border-bottom;
    border-radius: 17px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }


  .vs-card--header h2{
    color: rgba(var(--vs-primary),1);
    text-align: center;
  }

  .vs-card--header h4{
    text-align: center;
  }
  

</style>

<style lang="scss">
.vs-card--content {
  background: white !important;
  border-radius: 17px;
}
</style>
