import { render, staticRenderFns } from "./CustomCard.vue?vue&type=template&id=41075b64&scoped=true"
import script from "./CustomCard.vue?vue&type=script&lang=js"
export * from "./CustomCard.vue?vue&type=script&lang=js"
import style0 from "./CustomCard.vue?vue&type=style&index=0&id=41075b64&lang=scss&scoped=true"
import style1 from "./CustomCard.vue?vue&type=style&index=1&id=41075b64&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41075b64",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\OnPoint-Front\\OnPoint-Front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41075b64')) {
      api.createRecord('41075b64', component.options)
    } else {
      api.reload('41075b64', component.options)
    }
    module.hot.accept("./CustomCard.vue?vue&type=template&id=41075b64&scoped=true", function () {
      api.rerender('41075b64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/components/custom-card/CustomCard.vue"
export default component.exports